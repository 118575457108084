import React, { useState, useEffect } from "react";
import { csvVariableDownload, loadCsvFile } from "../../utils/streanCsv";
import { sliceTwitterCsv } from "../../utils/dataManipulation/dataManipulation";
import "./css/FilterModalTwitter.css";
import TwitterFilterInputs from "../inputs/TwitterFilterInputs";
const FilterModalTwitter = ({ onToggle }) => {
  const csvFileUrl = `${process.env.PUBLIC_URL}/twitter_v1.csv`;
  // Get today's date and calculate October 7th of the current year
  const today = new Date();
  // Set the start date to October 7th of the current year
  const startDate = new Date(2023, 9, 7).toISOString().split("T")[0]; // October 7th, 2023
  // Set the end date to today
  const endDate = today.toISOString().split("T")[0]; // Format as yyyy-mm-dd

  const [csvData, setCsvData] = useState([]);
  const [filters, setFilters] = useState({
    description: "",
    dateStart: startDate,
    dateEnd: endDate,
    user_posted: [],
    tagged_users: [],
    hashtags: [],
    repliesMin: 0,
    repliesMax: Number.MAX_SAFE_INTEGER,
    repostsMin: 0,
    repostsMax: Number.MAX_SAFE_INTEGER,
    likesMin: 0,
    likesMax: Number.MAX_SAFE_INTEGER,
    viewsMin: 0,
    viewsMax: Number.MAX_SAFE_INTEGER,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initCsvData = async () => {
      try {
        const data = await loadCsvFile(csvFileUrl); // Load CSV file asynchronously
        await setCsvData(data); // Set the data without awaiting
      } catch (error) {
        console.error("Error loading CSV data:", error); // Log error if loading fails
      } finally {
        setLoading(false); // Set loading state to false after data is loaded
      }
    };

    initCsvData(); // Call the async function
  }, [csvFileUrl]); // This effect depends on the csvFileUrl

  const onApply = () => {
    let filteredCsv = sliceTwitterCsv(csvData, filters);

    console.log(`Filtered Rows: ${filteredCsv.length}`);
    if (filteredCsv.length > 0) {
      csvVariableDownload(filteredCsv);
      onToggle();
    } else {
      alert(
        "No data is matching the search.\n please check different filters "
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name);
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleAddFeatureItem = (feature, item) => {
    console.log("item:", item);

    setFilters((prevFilters) => {
      // Check if item already exists in the list
      if (!prevFilters[feature].includes(item)) {
        const updatedFilters = {
          ...prevFilters,
          [feature]: [...prevFilters[feature], item],
        };
        console.log(updatedFilters[feature]); // Logs the updated state
        return updatedFilters;
      }

      // If item already exists, return the previous state (no update)
      return prevFilters;
    });
  };
  //   console.log("item");
  //   console.log(item);
  //   setFilters((prevFilters) => {
  //     const updatedFilters = {
  //       ...prevFilters,
  //       [feature]: [...prevFilters[feature], item],
  //     };
  //     console.log(updatedFilters[feature]); // Logs the updated state
  //     return updatedFilters;
  //   });
  // };

  const handleRemoveItem = (feature, item) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [feature]: prevFilters[feature].filter(
        (currentItem) => currentItem !== item
      ),
    }));
  };
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Apply Filters</h2>
        <TwitterFilterInputs
          filters={filters}
          handleChange={handleChange}
          handleAddFeatureItem={handleAddFeatureItem}
          handleRemoveItem={handleRemoveItem}
        />
        <button onClick={onApply} disabled={loading}>
          {loading ? "Loading..." : "Apply Filters"}
        </button>
        <button onClick={onToggle}>Close</button>
      </div>
    </div>
  );
};

export default FilterModalTwitter;
