import { useEffect, useState } from "react";
import "./App.css";
import LoginScreen from "./screens/LoginScreen";
import ResearchScreen from "./screens/ResearchScreen";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem("isLoggedIn") === "true";
  });

  useEffect(() => {
    localStorage.setItem("isLoggedIn", isLoggedIn);
  }, [isLoggedIn]);

  return (
      <div className="App">
        {isLoggedIn ? (
            <ResearchScreen />
        ) : (
            <LoginScreen onLogin={setIsLoggedIn} />
        )}
      </div>
  );
}

export default App;
